import * as React from 'react';

const PauseIcon = ({ fill, scale = 1 }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20 * scale}
      height={26 * scale}
      viewBox="0 0 20 26"
    >
      <path
        fill={fill}
        fillRule="nonzero"
        d="M4 2a2 2 0 0 0-2 2v18a2 2 0 1 0 4 0V4a2 2 0 0 0-2-2zm0-2a4 4 0 0 1 4 4v18a4 4 0 1 1-8 0V4a4 4 0 0 1 4-4zm12 2a2 2 0 0 0-2 2v18a2 2 0 1 0 4 0V4a2 2 0 0 0-2-2zm0-2a4 4 0 0 1 4 4v18a4 4 0 1 1-8 0V4a4 4 0 0 1 4-4z"
      />
    </svg>
  );
};

export default PauseIcon;
