import React, { memo } from 'react';
import { Hls } from '../Hls/Hls';
import { isHlsNativelySupported, mergeRefs } from '@voomly/utils';

const VideoFrameComponent = ({
  url,
  videoRef,
  fromTime,
  ...videoProps
}: {
  url: string;
  fromTime: number;
  videoRef: React.Ref<HTMLVideoElement>;
} & React.HTMLProps<HTMLVideoElement>) => {
  return isHlsNativelySupported() ? (
    <video
      //  If the developer does not set the poster attribute,
      //  Android will set its own poster image, which does not have the proper origin and will cause a CORS exception.
      poster="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
      ref={mergeRefs(videoRef)}
      {...videoProps}
    >
      <source src={`${url}#t=${fromTime}`} type="application/x-mpegURL" />
    </video>
  ) : (
    <Hls url={url} skipBotDetection load startPosition={fromTime}>
      {({ registerVideoRef }) => (
        <video
          //  If the developer does not set the poster attribute,
          //  Android will set its own poster image, which does not have the proper origin and will cause a CORS exception.
          poster="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          ref={mergeRefs<HTMLVideoElement>(videoRef, registerVideoRef)}
          {...videoProps}
        />
      )}
    </Hls>
  );
};

export const VideoFrame = memo(VideoFrameComponent);
