import * as React from 'react';

const PlayIcon = ({ fill, scale = 1 }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28 * scale}
      height={33 * scale}
      viewBox="0 0 33 36"
    >
      <g transform="translate(2,2)">
        <path
          fill={fill}
          fillRule="nonzero"
          d="M0 4.993v22.011a5 5 0 0 0 7.535 4.31l18.71-11.005a5 5 0 0 0 0-8.619L7.534.683A5 5 0 0 0 0 4.993z"
        />
      </g>
    </svg>
  );
};

export default PlayIcon;
