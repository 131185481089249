import { createStandardAction } from 'typesafe-actions';
import { IGeneralVideoControlInterface } from '../../components/Video/videoControlInterface';
import { IVideoState } from './videoStateReducer';
import { PlayerExternalAPI } from '../../api/external/PlayerExternalAPI';

export const changeControls = createStandardAction(
  'videoState/CHANGE_CONTROLS'
)<Partial<IVideoState>>();

export const requestPlay = createStandardAction('videoState/REQUEST_PLAY')<{
  time?: number;
  isSilent?: boolean;
}>();

export const playRequested = createStandardAction(
  'videoState/PLAY_REQUESTED'
)();

export const videoPlayStarted = createStandardAction(
  'videoState/VIDEO_PLAY_STARTED'
)();

export const requestPause = createStandardAction('videoState/PAUSE_REQUESTED')<{
  isSilent?: boolean;
}>();

export const setPlayPauseSilentMode = createStandardAction(
  'videoState/SET_PLAY_PAUSE_SILENT_MODE'
)<{
  isSilent: boolean;
}>();

export const videoPaused = createStandardAction('videoState/VIDEO_PAUSED')();

export const requestSeek = createStandardAction('videoState/SEEK_REQUESTED')<
  (
    | {
        time: number;
        type: 'toTime';
      }
    | { type: 'relative'; bySeconds: number }
    | { type: 'percent'; percent: number }
  ) & {
    initiatedBy?: 'click' | 'playWith';
  }
>();

export const requestToggleMute = createStandardAction(
  'videoState/TOGGLE_MUTE'
)();
export const requestTogglePlayPause = createStandardAction(
  'videoState/TOGGLE_PLAY_PAUSE'
)();

export const videoSeekStarted = createStandardAction(
  'videoState/VIDEO_SEEK_STARTED'
)<{
  initiatedBy?: 'click' | 'playWith';
  toTime: number;
  currentTime: number;
}>();

export const videoSeeked = createStandardAction('videoState/VIDEO_SEEKED')<{
  initiatedBy?: 'click' | 'playWith';
  time: number;
  seekType: 'seeking' | 'seeked';
}>();

export const updateTime = createStandardAction('videoState/TIME_UPDATED')<{
  newTime: number;
}>();

export const playEnded = createStandardAction('videoState/PLAY_ENDED')();

export const requestVolumeChange = createStandardAction(
  'videoState/VOLUME_CHANGE_REQUESTED'
)<
  | { mute: false; type: 'mute'; volume?: number }
  | { mute: true; by?: 'autoplay'; volume?: number; type: 'mute' }
  | {
      volume: number;
      type: 'absoluteVolume';
    }
  | {
      byPercent: number;
      type: 'relativeVolume';
    }
>();

export const requestQualityChange = createStandardAction(
  'videoState/QUALITY_CHANGE_REQUESTED'
)<{
  quality: number;
  hlsUrl: string;
}>();

export const qualityChanged = createStandardAction(
  'videoState/QUALITY_CHANGED'
)<{
  quality: number;
}>();

export const volumeChanged = createStandardAction('videoState/VOLUME_CHANGED')<{
  volume: number;
  muted: boolean;
}>();

export const videoIsReady = createStandardAction('videoState/VIDEO_READY')();

export const requestAutoplay = createStandardAction(
  'videoState/AUTOPLAY_REQUESTED'
)<{ muted: boolean }>();

export const requestVideoBuffer = createStandardAction(
  'videoState/VIDEO_BUFFER_REQUESTED'
)<{ time: number; initiatedBy: 'nextVideoPlay' }>();
export const videoBuffered = createStandardAction('videoState/VIDEO_BUFFERED')<{
  time: number;
  initiatedBy: 'nextVideoPlay';
}>();

export const requestSeekAndBuffer = createStandardAction(
  'videoState/REQUEST_SEEK_AND_BUFFER'
)<{ time: number; doubleSeek: boolean }>();

export const requestSpeedChange = createStandardAction(
  'videoState/REQUEST_SPEED_CHANGE'
)<{ value: number }>();
export const speedChanged = createStandardAction(
  'videoState/SPEED_CHANGED'
)<number>();

export const percentLoadedChanged = createStandardAction(
  'videoState/PERCENTS_LOADED_CHANGED'
)<number>();

export const registerVideoApi = createStandardAction(
  'videoState/REGISTER_VIDEO_API'
)<{
  api: IGeneralVideoControlInterface;
}>();

export const registerPlayerExternalApi = createStandardAction(
  'videoState/REGISTER_PLAYER_EXTERNAL_API'
)<{
  api: PlayerExternalAPI;
}>();

export const getSpeedOptions = createStandardAction(
  'videoState/GET_SPEED_OPTIONS'
)();

export const getQualityOptions = createStandardAction(
  'videoState/GET_QUALITY_OPTIONS'
)();

export const setIsBotDetected = createStandardAction(
  'videoState/SET_BOT_DETECTED'
)<boolean>();
