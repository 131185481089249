import React, { memo, useMemo } from 'react';
import type { PlayerExternalAPI } from '@voomly/player';
import {
  getLastVisitWatchSeconds,
  IPasswordProtectedPlayerVideo,
  IPasswordProtection,
  IPlaybackSettings,
  IPlayerVideo,
  IPlayerWithVideo,
  PlayerContainerFromEmbedVideoId,
  PlayerMode,
  VideoItemsContainerProvider,
} from '@voomly/player';
import { normalizeRatio } from '@voomly/utils';
import { FunnelsPlayer } from './parts/FunnelsPlayer';
import { useMount } from 'react-use';

const OriginalViewComponent = ({
  id,
  type,
  ratio,
  onLoaded,
  onLoadedPasswordForm,
  playerExternalAPI,
  disableShortcuts,
  onMounted,
  skinColor,
}: {
  id: string;
  type: 'v' | 'f';
  ratio: string | '16:9' | 'auto';
  onLoaded?: (playerConfig: IPlayerWithVideo, file: IPlayerVideo) => void;
  onLoadedPasswordForm?: (
    playerConfig: IPasswordProtection,
    file: IPasswordProtectedPlayerVideo
  ) => void;
  playerExternalAPI?: PlayerExternalAPI;
  disableShortcuts?: boolean;
  onMounted?: () => void;
  skinColor?: string;
}) => {
  const initialPlaybackSettings: IPlaybackSettings = useMemo(
    () => ({
      currentTime: 0,
      lastVisitWatchedSeconds: getLastVisitWatchSeconds(id),
    }),
    [id]
  );

  const normalizedRatio = normalizeRatio(ratio) || 1.7777;

  useMount(() => {
    onMounted && onMounted();
  });

  let content: React.ReactElement | undefined = undefined;
  if (type === 'v') {
    content = (
      <VideoItemsContainerProvider>
        <PlayerContainerFromEmbedVideoId
          ratio={normalizedRatio}
          embedVideoId={id}
          playerMode={PlayerMode.NORMAL}
          playbackSettings={initialPlaybackSettings}
          isRememberDismissEnabled={true}
          onLoaded={onLoaded}
          onLoadedPasswordForm={onLoadedPasswordForm}
          playerExternalAPI={playerExternalAPI}
          disableShortcuts={disableShortcuts}
          skinColor={skinColor}
        />
      </VideoItemsContainerProvider>
    );
  }

  if (type === 'f') {
    content = (
      <FunnelsPlayer
        funnelId={id}
        playerMode={PlayerMode.NORMAL}
        ratio={normalizedRatio}
        isRememberDismissEnabled={true}
        disableShortcuts={disableShortcuts}
        playerExternalAPI={playerExternalAPI}
        skinColor={skinColor}
      />
    );
  }

  if (!content) {
    console.error(`Unknown playable type: ${type}`);
    return <div />;
  }

  return content;
};

export const OriginalView = memo(OriginalViewComponent);
