import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import to from 'await-to-js';
import {
  getEmbedVideoPublicRequest,
  isEmbedVideoPasswordProtected,
} from './api/embedVideo';
import {
  ICallbacks as IOriginalCallbacks,
  IPlaybackSettings,
  IPlayerContainerProps,
  ISourceConfiguration as IOriginalSourceConfiguration,
  PlayerContainer,
} from './components/Player/PlayerContainer';
import { Ratio, Loader } from '@voomly/ui/player-deps';
import { IError } from './components/ErrorStub/types';
import { AskToResumeOnVisitWatcher } from './watchers/AskToResumeOnVisitWatcher/AskToResumeOnVisitWatcher';
import { ITimelineItem } from './types/types';
import { VideoItemsContainerProvider } from './components/VideoItems/VideoItemsContainerContext';
import { IPlayerWithVideo, IRatio } from './types/player';
import { IPasswordProtectedPlayerVideo, IPlayerVideo } from './types/video';
import {
  IEmbedPlayerData,
  IPasswordProtectedEmbedPlayerData,
  IPasswordProtection,
} from './types/embedVideo';
import { autofixPlayerWithVideoConfig } from './autofixers/autofixPlayerWithVideoConfig';
import { DetachableStoreProviderForPlayer } from './contexts/DetachableStoreProviderForPlayer';
import { PlayerComponentsProvider } from './components/VideoItems/contextProviders/PlayerComponentsProvider';
import { PlayerMode } from './components/types/defaultPropTypes';
import { PlayerExternalAPI } from './api/external/PlayerExternalAPI';
import { PlayerExternalAPIContext } from './components/VideoItems/PlayerExternalAPIContext';

// Lazy components
const LazyUnlockVideo = React.lazy(
  () => import('./components/Unlock/UnlockVideo')
);
const LazyErrorStub = React.lazy(() => import('./components/ErrorStub'));

// We do something with this ISourceConfiguration to get IOriginalSourceConfiguration,
// that's the whole point of this Class.
interface ISourceConfiguration {
  ratio: IRatio;
  embedVideoId: string | undefined;
  skinColor?: string;

  playerMode: PlayerMode;
  playbackSettings: IPlaybackSettings;

  // Options
  disableShortcuts?: boolean;
}

interface ICallbacks extends IOriginalCallbacks {
  onLoaded?: (playerConfig: IPlayerWithVideo, file: IPlayerVideo) => void;
  onLoadedPasswordForm?: (
    playerConfig: IPasswordProtection,
    file: IPasswordProtectedPlayerVideo
  ) => void;
}

interface IAPIConfiguration {
  playerExternalAPI?: PlayerExternalAPI;
}

type PlayerContainerFromEmbedVideoIdProps = ISourceConfiguration &
  ICallbacks &
  IAPIConfiguration &
  Omit<IPlayerContainerProps, keyof IOriginalSourceConfiguration>;

export const PlayerContainerFromEmbedVideoIdComponent = ({
  embedVideoId,
  ratio,
  skinColor,
  onVideoFinished,
  onNavigateToOtherNodeRequest,
  playbackSettings,
  playerMode,
  onTimeChange,
  isRememberDismissEnabled,
  onLoaded,
  onLoadedPasswordForm,
  disableShortcuts,
  playerExternalAPI,
}: PlayerContainerFromEmbedVideoIdProps) => {
  const askToResumeOnVisitWatcherRef = useRef<AskToResumeOnVisitWatcher>();

  const [file, setFile] = useState<IPlayerVideo>();
  const [playerConfig, setPlayerConfig] =
    useState<IOriginalSourceConfiguration['playerConfig']>();
  const [
    passwordProtectedEmbedPlayerData,
    setPasswordProtectedEmbedPlayerData,
  ] = useState<IPasswordProtectedEmbedPlayerData | undefined>(undefined);
  const [error, setError] = useState<IError | undefined>(undefined);

  const emptyTimelineItems: ITimelineItem[] = useMemo(() => [], []);

  const fetchEmbedVideo = useCallback(async () => {
    if (!embedVideoId) {
      setError({
        message: 'Video is missing',
      });
      return;
    }
    const [err, response] = await to(getEmbedVideoPublicRequest(embedVideoId));

    if (response) {
      const { data } = response;

      if (isEmbedVideoPasswordProtected(data)) {
        setPasswordProtectedEmbedPlayerData(data);
        onLoadedPasswordForm?.(data.player, data.video);
      } else {
        // TODO: boundToVideo refactoring, check if it works
        data.player.videoId = data.video?.id;

        const embedVideo = {
          ...data,
          player: autofixPlayerWithVideoConfig(data.player),
        };

        setFile(embedVideo.video);
        setPlayerConfig(embedVideo.player);

        askToResumeOnVisitWatcherRef.current?.assignPlayer(
          embedVideo.player,
          embedVideo.video,
          playerMode
        );
        onLoaded?.(embedVideo.player, embedVideo.video);
      }
      return;
    }

    setError({ message: err!.message });
  }, [playerMode, embedVideoId, onLoaded, onLoadedPasswordForm]);

  useEffect(() => {
    askToResumeOnVisitWatcherRef.current = new AskToResumeOnVisitWatcher();
    askToResumeOnVisitWatcherRef.current.setEmbedVideoId(embedVideoId);
    fetchEmbedVideo();

    return () => {
      askToResumeOnVisitWatcherRef?.current?.destruct();
      askToResumeOnVisitWatcherRef.current = undefined;
    };
  }, [embedVideoId, fetchEmbedVideo]);

  const handleOnTimeChange = useCallback(
    (currentTime: number) => {
      askToResumeOnVisitWatcherRef.current?.updateLastTime(currentTime);
      onTimeChange?.(currentTime);
    },
    [onTimeChange]
  );

  const handleUnlockVideo = useCallback(
    (password: string, { player, video }: IEmbedPlayerData) => {
      setFile(video);
      setPlayerConfig(player);
      setPasswordProtectedEmbedPlayerData(undefined);

      askToResumeOnVisitWatcherRef.current?.assignPlayer(
        player,
        video,
        playerMode
      );

      onLoaded?.(player, video);

      if (playerExternalAPI) {
        playerExternalAPI.fromEmitter.timelineUnlock.emit({
          password,
        });
      }
    },
    [playerExternalAPI, playerMode, onLoaded]
  );

  const renderLoader = useCallback(() => {
    return (
      <Loader
        color={
          playerConfig?.skin.bgColorEnabled
            ? playerConfig.skin.bgColor
            : skinColor
        }
      />
    );
  }, [playerConfig, skinColor]);

  const renderPasswordForm = () => {
    if (!passwordProtectedEmbedPlayerData || !embedVideoId) {
      return null;
    }

    return (
      <Ratio ratio={ratio}>
        <Suspense fallback={renderLoader()}>
          <LazyUnlockVideo
            embedVideoId={embedVideoId}
            lockedPlayer={passwordProtectedEmbedPlayerData.player}
            lockedFile={passwordProtectedEmbedPlayerData.video}
            onUnlock={handleUnlockVideo}
          />
        </Suspense>
      </Ratio>
    );
  };

  if (file && playerConfig) {
    const passThroughProps: IPlayerContainerProps = {
      onTimeChange: handleOnTimeChange,
      onVideoFinished,
      onNavigateToOtherNodeRequest,
      playbackSettings,
      playerMode,
      isRememberDismissEnabled,
      playerConfig,
      file,
      playerExternalAPI,
      ratio,
      disableShortcuts,
    };

    return (
      <PlayerExternalAPIContext.Provider value={playerExternalAPI}>
        <VideoItemsContainerProvider>
          <PlayerComponentsProvider>
            <DetachableStoreProviderForPlayer
              timelineItems={playerConfig.timelineItems ?? emptyTimelineItems}
              thumbnailItems={playerConfig.thumbnailItems ?? emptyTimelineItems}
            >
              <PlayerContainer {...passThroughProps} />
            </DetachableStoreProviderForPlayer>
          </PlayerComponentsProvider>
        </VideoItemsContainerProvider>
      </PlayerExternalAPIContext.Provider>
    );
  }

  if (passwordProtectedEmbedPlayerData) {
    return renderPasswordForm();
  }

  return (
    <Ratio ratio={ratio}>
      {error ? (
        <Suspense fallback={renderLoader}>
          <LazyErrorStub error={error} />
        </Suspense>
      ) : (
        renderLoader()
      )}
    </Ratio>
  );
};

export const PlayerContainerFromEmbedVideoId = memo(
  PlayerContainerFromEmbedVideoIdComponent
);
