import React, { useRef, useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { usePrevious } from 'react-use';
import styled, { css } from 'styled-components';
import { OptimizedImage, VideoFrame } from '@voomly/ui/player-deps';
import styles from './thumbnail.module.sass';

const FirstFrameWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}
`;

const FirstFrame = styled.img`
  width: 100%;
  max-height: 100%;

  object-fit: cover;
`;

// Blur is needed to smooth out desync of firstFrame image and video playing
const VideoLoopStyled = styled.div<{ showBlur: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  overflow: hidden;

  will-change: filter;

  filter: blur(0px);
  transition: 0.4s filter ease-out;

  video {
    max-width: 100%;
    max-height: 100%;
  }

  ${({ showBlur }) =>
    showBlur &&
    css`
      filter: blur(10px);
    `}
`;

// This component is used in settings tab and in player
// So it has some useEffect that are needed for frame sliding in settings tab
export const VideoLoop = ({
  fromTime,
  toTime,
  shouldPlay = true, // Is used for frames scrolling
  paused = false, // Is used when play button clicked
  videoUrl,
  onFirstLoadStarted,
  onFirstLoadFinished,
  onSeeking,
  onCanPlay,
  firstFrameUrl,
  showBlur = false,
  forceShowPreviewFallback,
  previewFallbackUrl,
}: {
  fromTime: number;
  toTime: number;
  videoUrl: string;
  shouldPlay?: boolean;
  onFirstLoadStarted?(): void;
  onFirstLoadFinished?(): void;
  onSeeking?(): void;
  onCanPlay?(): void;
  firstFrameUrl?: string;
  showBlur?: boolean;
  paused?: boolean;
  forceShowPreviewFallback?: boolean;
  previewFallbackUrl?: string;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const prevShoudPlay = usePrevious(shouldPlay);
  const [canPlay, setCanPlay] = useState(false);

  useEffect(() => {
    if (shouldPlay) return;
    if (!videoRef.current) return;

    // With this useEffect user can slide to `toTime` and see the frame

    videoRef.current.currentTime = toTime;
  }, [toTime, shouldPlay]);

  useEffect(() => {
    if (!videoRef.current) return;

    if (shouldPlay && !prevShoudPlay && !paused) {
      videoRef.current.currentTime = fromTime;
      videoRef.current.play().catch(() => {});
    } else if ((!shouldPlay && prevShoudPlay) || paused) {
      videoRef.current.pause();
    }
  }, [shouldPlay, prevShoudPlay, fromTime, paused]);

  useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.currentTime = fromTime;
  }, [fromTime]);

  const handleTimeUpdate = useCallback(() => {
    if (!videoRef.current) return;
    if (!shouldPlay) return;

    const videoEl = videoRef.current;

    if (videoEl.currentTime >= toTime) {
      videoEl.currentTime = fromTime;
    }
  }, [fromTime, toTime, shouldPlay]);

  const isFirstFrameVisible = Boolean(firstFrameUrl && !canPlay);

  return (
    <VideoLoopStyled showBlur={isFirstFrameVisible || showBlur}>
      {forceShowPreviewFallback ? (
        previewFallbackUrl ? (
          <OptimizedImage
            className={cx(styles.image, styles.frameImage)}
            url={firstFrameUrl ?? previewFallbackUrl}
            size="auto"
            alt="Video preview"
          />
        ) : (
          <div></div>
        )
      ) : (
        <VideoFrame
          url={videoUrl}
          videoRef={videoRef}
          fromTime={fromTime}
          autoPlay
          playsInline
          muted
          controls={false}
          crossOrigin="anonymous"
          onTimeUpdate={handleTimeUpdate}
          onLoadStart={onFirstLoadStarted}
          onLoadedData={onFirstLoadFinished}
          onSeeking={onSeeking}
          onCanPlayThrough={() => {
            setCanPlay(true);
            onCanPlay?.();
          }}
        />
      )}
      <FirstFrameWrapper isVisible={isFirstFrameVisible}>
        <FirstFrame src={firstFrameUrl} alt="Thumbnail" />
      </FirstFrameWrapper>
    </VideoLoopStyled>
  );
};
