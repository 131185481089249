import { IPlayerRootReducerShape } from '../rootPlayerStore';

export const isPlayBlocked = (state: IPlayerRootReducerShape) => {
  return state.videoState.isPlayBlockedBySideEffect;
};

export const getIsVideoReady = (state: IPlayerRootReducerShape) => {
  return state.videoState.isVideoReady;
};

export const getIsVideoMuted = (state: IPlayerRootReducerShape) => {
  return state.videoState.muted;
};

export const getQuality = (state: IPlayerRootReducerShape) => {
  return state.videoState.quality;
};

export const getVolume = (state: IPlayerRootReducerShape) => {
  return state.videoState.volume;
};

export const getSpeed = (state: IPlayerRootReducerShape) => {
  return state.videoState.speed;
};

export const getVideoState = (state: IPlayerRootReducerShape) => {
  return state.videoState;
};

export const getIsVideoPaused = (state: IPlayerRootReducerShape) => {
  return state.videoState.paused;
};

export const getCurrentTime = (state: IPlayerRootReducerShape) => {
  return state.videoState.currentTime;
};

export const getIsVideoPlaying = (state: IPlayerRootReducerShape) => {
  return !state.videoState.paused && !state.videoState.isEnded;
};

export const getIsPlayRequested = (state: IPlayerRootReducerShape) => {
  return state.videoState.isPlayRequested;
};

export const getHasStarted = (state: IPlayerRootReducerShape) => {
  return state.videoState.hasStarted;
};

export const getShouldVideoBeVisible = (state: IPlayerRootReducerShape) =>
  !state.thumbnail.isVisible ||
  (state.videoState.hasStarted && !state.videoState.isEnded);

export const getIsVideoFinished = (state: IPlayerRootReducerShape) => {
  return state.videoState.isEnded;
};

export const getWasVideoFinished = (state: IPlayerRootReducerShape) => {
  return state.videoState.wasEnded;
};

export const getIsBotDetected = (state: IPlayerRootReducerShape) => {
  return state.videoState.isBotDetected;
};
