import * as React from 'react';
import HlsVideo from './HlsVideo/HlsVideo';
import NativeVideo from './NativeVideo/NativeVideo';
import {
  IGeneralVideoControlInterface,
  IGeneralVideoControlProps,
  IInternalVideoProps,
} from './videoControlInterface';
import { isHlsNativelySupported } from '@voomly/utils';
import { endsWith } from 'lodash-es';
import { IPlayerTemplate } from '../../types/player';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  percentLoadedChanged,
  playEnded,
  playRequested,
  qualityChanged,
  speedChanged,
  updateTime,
  videoIsReady,
  videoPaused,
  videoPlayStarted,
  videoSeeked,
  volumeChanged,
} from '../../store/videoState/actions';
import {
  getIsVideoChanging,
  getNextSourceConfigurationTime,
  getPlayerMode,
} from '../../store/sourceConfiguration/selectors';
import { getIsBotDetected } from '../../store/videoState/selectors';
import { PlayerMode } from '../../components/types/defaultPropTypes';

interface IProps {
  streamingUrl: string;
  player: IPlayerTemplate;
  onVideoSourceError: (message: string) => void;
  onVideoBufferingToggle: (isBuffering: boolean) => void;
  onBotDetection?: (isBotDetected: boolean) => void;
  registerVideoApi: (videoApi: IGeneralVideoControlInterface) => void;
  videoRef: React.RefObject<HTMLVideoElement>;
}

export const Video = ({
  streamingUrl,
  registerVideoApi,
  player,
  onVideoSourceError,
  onVideoBufferingToggle,
  onBotDetection,
  videoRef,
}: IProps) => {
  const dispatch = useDispatch();
  const isChangingVideos = useSelector(getIsVideoChanging);
  const nextCurrentTime = useSelector(getNextSourceConfigurationTime);
  const isBotDetected = useSelector(getIsBotDetected);
  const playerMode = useSelector(getPlayerMode);

  let VideoComponent = isHlsNativelySupported() ? NativeVideo : HlsVideo;

  // mp4 is used only for local files to make possible add local video to our player
  if (endsWith(streamingUrl, '.mp4')) {
    VideoComponent = NativeVideo;
  }

  const onReady = useCallback(() => {
    dispatch(videoIsReady());
  }, [dispatch]);
  const onPlayRequested = useCallback(() => {
    dispatch(playRequested());
  }, [dispatch]);
  const onPlay = useCallback(() => {
    dispatch(videoPlayStarted());
  }, [dispatch]);
  const onPause = useCallback(() => {
    dispatch(videoPaused());
  }, [dispatch]);
  const onVolumeChange = useCallback(
    (volume: number, muted: boolean) => {
      dispatch(volumeChanged({ volume, muted }));
    },
    [dispatch]
  );
  const onRateChange = useCallback(
    (playbackRate: number) => {
      dispatch(speedChanged(playbackRate));
    },
    [dispatch]
  );
  const onTimeUpdate = useCallback(
    (time: number) => {
      if (isChangingVideos) return;

      dispatch(updateTime({ newTime: time }));
    },
    [dispatch, isChangingVideos]
  );
  const onPercentsLoaded = useCallback(
    (fraction: number) => {
      if (isChangingVideos) return;

      dispatch(percentLoadedChanged(fraction));
    },
    [dispatch, isChangingVideos]
  );
  const onSeeking = useCallback(
    (time: number, type: 'seeking' | 'seeked') => {
      dispatch(videoSeeked({ time, seekType: type }));
    },
    [dispatch]
  );
  const onQualityChange = useCallback(
    (quality: number) => {
      if (isChangingVideos) return;

      dispatch(qualityChanged({ quality }));
    },
    [dispatch, isChangingVideos]
  );
  const onEnded = useCallback(() => {
    dispatch(playEnded());
  }, [dispatch]);

  const videoComponentProps: IGeneralVideoControlProps & IInternalVideoProps = {
    url: streamingUrl,
    autoplay: player.general.autoplay,
    loop: player.general.onEnd.action === 'LOOP',
    muted: player.general.muted,
    onMount: registerVideoApi,
    onVideoSourceError,
    onReady,
    onPlayRequested,
    onPlay,
    onPause,
    onVolumeChange,
    onQualityChange,
    onRateChange,
    onTimeUpdate,
    onPercentsLoaded,
    onSeeking,
    onEnded,
    nextCurrentTime,
    onVideoBufferingToggle,
    onBotDetection,
    isBotDetected,
    skipBotDetection: playerMode !== PlayerMode.NORMAL,
    videoRef,
  };

  return <VideoComponent {...videoComponentProps} />;
};
