import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { IGeneralVideoControlInterface } from '../../../components/Video/videoControlInterface';
import {
  getFile,
  getFunnelId,
  getIsVideoChanging,
} from '../../sourceConfiguration/selectors';
import {
  registerVideoApi,
  requestAutoplay,
  requestPause,
  requestPlay,
  requestQualityChange,
  requestSeek,
  requestSeekAndBuffer,
  requestSpeedChange,
  requestToggleMute,
  requestTogglePlayPause,
  requestVideoBuffer,
  requestVolumeChange,
  videoBuffered,
  videoSeekStarted,
} from '../actions';
import {
  getCurrentTime,
  getIsBotDetected,
  getVideoState,
  isPlayBlocked,
} from '../selectors';
import { isIOS } from '@voomly/utils';
import { forceVideoBufferingWithMinDelay } from '../../../utils/forceVideoBuffering';
import { getFunnelState } from '../../funnelState/selectors';

// This saga only makes calls to the video api!
// If you need to add some logic, please do in separate saga.
// If you need prev & next api you can do this:
//
// function* mySaga() {
//   let currentApi = (
//     (yield take(registerVideoApi)) as ReturnType<typeof registerVideoApi>
//   ).payload.api;
//
//   while (true) {
//     const nextApi = (
//       (yield take(registerVideoApi)) as ReturnType<typeof registerVideoApi>
//     ).payload.api;
//
//     // do something
//
//     currentApi = nextApi;
//   }
// }

const tryPlay = async (
  videoApi: IGeneralVideoControlInterface,
  isAutoplay?: boolean
) => {
  try {
    await videoApi.play(isAutoplay);

    return 'ok';
  } catch (e) {
    console.error('[voomly-embed-player] Failed to start play video', e);

    return 'error';
  }
};

function* seekAndPlay(
  videoApi: IGeneralVideoControlInterface,
  time: number | undefined
) {
  if (time !== undefined) {
    yield put(requestSeek({ time, initiatedBy: 'playWith', type: 'toTime' }));
  }

  yield call(tryPlay, videoApi);
}

function* handleRequestPlayPause(videoApi: IGeneralVideoControlInterface) {
  if (videoApi.getIsPlaying()) {
    videoApi.pause();
  } else {
    yield call(tryPlay, videoApi);
  }
}

function handleMuteToggle(videoApi: IGeneralVideoControlInterface) {
  if (videoApi.getIsMuted()) {
    videoApi.setMuted(false);
  } else {
    videoApi.setMuted(true);
  }
}

function* handleRequestPlay(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestPlay>
) {
  if (getIsVideoChanging(yield select()) || isPlayBlocked(yield select())) {
    return;
  }

  const { hasStarted, isPlayRequested, currentTime } = getVideoState(
    yield select()
  );

  if (!hasStarted && !isPlayRequested) {
    // In iOS video starts playing without audio, even if user directly clicked to the video
    // Video playing should start directly after click(without timeouts),
    // so that's why we skip forceVideoBufferingWithMinDelay
    if (!isIOS) {
      yield call<typeof forceVideoBufferingWithMinDelay>(
        forceVideoBufferingWithMinDelay,
        videoApi,
        currentTime
      );
    }
  } else {
    // Quickfix: initial "play" triggered, next "play" shouldn't hide the thumb
    if (!hasStarted && isPlayRequested && !getIsBotDetected(yield select())) {
      return;
    }
  }

  yield call(seekAndPlay, videoApi, action.payload.time);
}

function* handleRequestPause(
  videoApi: IGeneralVideoControlInterface,
  _action: ReturnType<typeof requestPause>
) {
  if (getIsVideoChanging(yield select())) return;
  videoApi.pause();
}

function* handleSeek(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestSeek>
) {
  if (getIsVideoChanging(yield select())) return;

  let timeToSeek: number | undefined = undefined;

  const duration =
    videoApi.getDuration() || getFile(yield select())?.metadata.duration;

  if (!duration) {
    console.error(
      '[voomly-embed-player] Failed to get duration, skipping seek'
    );
    return;
  }

  if (action.payload.type === 'toTime') {
    timeToSeek = Math.min(duration, Math.max(action.payload.time, 0));
  } else if (action.payload.type === 'relative') {
    const newTime = videoApi.getCurrentTime() + action.payload.bySeconds;
    timeToSeek = Math.min(duration, Math.max(newTime, 0));
  } else {
    timeToSeek = videoApi.getDuration() * (action.payload.percent / 100);
  }

  if (timeToSeek !== undefined) {
    yield put(
      videoSeekStarted({
        initiatedBy: action.payload.initiatedBy,
        toTime: timeToSeek,
        currentTime: getCurrentTime(yield select()),
      })
    );

    videoApi.seek(timeToSeek);
  }
}

function handleVolumeChange(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestVolumeChange>
) {
  if (action.payload.type === 'mute') {
    videoApi.setMuted(action.payload.mute);
    videoApi.setVolume(action.payload.volume ?? videoApi.getVolume());
  } else if (action.payload.type === 'absoluteVolume') {
    videoApi.setMuted(false);
    videoApi.setVolume(action.payload.volume);
  } else if (action.payload.type === 'relativeVolume') {
    const currentVolume = videoApi.getVolume();

    const tmp = currentVolume + action.payload.byPercent / 100;
    const newVolume = Math.min(1, Math.max(tmp, 0));

    if (newVolume > 0) {
      videoApi.setMuted(false);
    }

    videoApi.setVolume(newVolume);
  }
}

function* handleAutoplay(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestAutoplay>
) {
  const isFunnel = !!getFunnelId(yield select());
  const funnelState = getFunnelState(yield select());
  let isMuted = action.payload.muted;
  let volume = videoApi.getVolume() ?? 1;
  if (isFunnel) {
    isMuted = funnelState.muted ?? isMuted;
    volume = funnelState.volume ?? volume;
  }

  if (isMuted) {
    // now we control sound not based on settings, but based on player capabilities
    yield put(
      requestVolumeChange({
        mute: true,
        volume,
        by: 'autoplay',
        type: 'mute',
      })
    );
    yield call(tryPlay, videoApi, true);
  } else {
    yield put(
      requestVolumeChange({
        volume,
        type: 'absoluteVolume',
      })
    );

    const status = yield call(tryPlay, videoApi, true);

    // if tryPlay failed, we should try to mute video and play again
    if (status === 'error') {
      yield put(
        requestVolumeChange({
          mute: true,
          volume,
          by: 'autoplay',
          type: 'mute',
        })
      );
      yield call(tryPlay, videoApi, true);
    }
  }
}

function* handleSeekAndBuffer(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestSeekAndBuffer>
) {
  yield call(
    () =>
      new Promise<void>((resolve) => {
        videoApi.seekAndBuffer(action.payload.time, () => {
          resolve();
        });
      })
  );

  if (action.payload.doubleSeek) {
    videoApi.seek(action.payload.time);
  }
}

function* handleVideoBuffer(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestVideoBuffer>
) {
  if (!isIOS) {
    yield call<typeof forceVideoBufferingWithMinDelay>(
      forceVideoBufferingWithMinDelay,
      videoApi,
      action.payload.time
    );
  }

  yield put(
    videoBuffered({
      time: action.payload.time,
      initiatedBy: action.payload.initiatedBy,
    })
  );
}

function handleSpeedChange(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestSpeedChange>
) {
  videoApi.setSpeed(action.payload.value);
}

function handleQualityChange(
  videoApi: IGeneralVideoControlInterface,
  action: ReturnType<typeof requestQualityChange>
) {
  videoApi.setQuality(action.payload.quality, action.payload.hlsUrl);
}

function* listenActions(action: ReturnType<typeof registerVideoApi>) {
  yield takeEvery(requestToggleMute, handleMuteToggle, action.payload.api);

  yield takeEvery(
    requestTogglePlayPause,
    handleRequestPlayPause,
    action.payload.api
  );

  yield takeEvery(requestPlay, handleRequestPlay, action.payload.api);
  yield takeEvery(requestPause, handleRequestPause, action.payload.api);
  yield takeEvery(requestSeek, handleSeek, action.payload.api);
  yield takeEvery(requestVolumeChange, handleVolumeChange, action.payload.api);
  yield takeEvery(requestAutoplay, handleAutoplay, action.payload.api);
  yield takeEvery(
    requestSeekAndBuffer,
    handleSeekAndBuffer,
    action.payload.api
  );
  yield takeEvery(requestVideoBuffer, handleVideoBuffer, action.payload.api);
  yield takeEvery(requestSpeedChange, handleSpeedChange, action.payload.api);
  yield takeEvery(
    requestQualityChange,
    handleQualityChange,
    action.payload.api
  );
}

export function* passActionsToVideoApiSaga() {
  yield takeLatest(registerVideoApi, listenActions);
}
