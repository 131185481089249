import { Dispatch } from 'redux';
import { EventEmitter } from 'eventemitter3';
import { omit } from '@voomly/utils';
import {
  FromPlayerEmitter,
  FromPlayerEventTypes,
} from './emitters/FromPlayerEmitter';
import { ToPlayerDispatcher } from './emitters/ToPlayerDispatcher';

import { FromIFrameEventEmitter } from './emitters/iframe/FromIFrameEventEmitter';
import { ToIFrameDispatcher } from './emitters/iframe/ToIFrameDispatcher';
import { PlayerExternalAPIConnectedChecker } from './PlayerExternalAPIConnectedChecker';

/**
 * External player API.
 *
 * @privateRemarks
 *
 * IMPORTANT: When creating/updating/deleting any method here,
 * the method name should be added to the methodsToInterceptMap in {@link PlayerExternalAPIInterceptor}
 */
export class PlayerExternalAPI {
  private destroyed = false;
  private readonly apiReadyEmitter = new EventEmitter<'ready'>();

  /**
   * @internal
   */
  constructor(
    // Video/Funnel id
    private id?: string,
    // Nodes to attach
    private toAttach?: {
      // To attach events listener to particular node
      mountNode?: HTMLDivElement;

      // To attach events listener to particular iframe
      isInIframe?: boolean;
    },
    /**
     * @internal
     */
    // To get events from player
    public fromEmitter = new FromPlayerEmitter(
      id,
      toAttach?.isInIframe ? new FromIFrameEventEmitter() : undefined
    ),
    // To send events to player
    private toEmitter = toAttach?.isInIframe
      ? new ToIFrameDispatcher()
      : new ToPlayerDispatcher()
  ) {
    if (toAttach?.mountNode) {
      const nodeConnectedChecker = new PlayerExternalAPIConnectedChecker(
        toAttach.mountNode,
        this.destroy
      );

      this.fromEmitter.setNode(toAttach?.mountNode);

      // Listen if node is not connected, so we don't need API be active anymore
      this.fromEmitter.setNodeConnectedChecker(nodeConnectedChecker);
      this.toEmitter.setNodeConnectedChecker(nodeConnectedChecker);
    }
  }

  /**
   * Check if API is initialized (ready to use) and not destroyed
   *
   * @group Common
   */
  public isInitialized = () =>
    this.toEmitter.isInitialized() &&
    this.fromEmitter.isInitialized() &&
    !this.isDestroyed();

  /**
   * Check if API is destroyed and not connected to node
   *
   * @group Common
   */
  public isDestroyed = () => {
    return this.destroyed;
  };

  /**
   * Destroy API instance and unsubscribe all events
   *
   * @group Common
   */
  public destroy = () => {
    this.apiReadyEmitter.off('ready');
    this.fromEmitter.destroy();
    this.toEmitter.destroy();

    this.destroyed = true;
  };

  /**
   * Register store for player internally. Not for public usage.
   *
   * @internal
   */
  public register = (dispatch: Dispatch, storeId: string) => {
    this.fromEmitter.register(storeId);
    this.toEmitter.register(dispatch);

    this.apiReadyEmitter.emit('ready');
  };

  /**
   * Subscribe to event when API is ready to use. Should be used when getting API
   * instance directly (not using Proxy object)
   *
   * @group Common
   */
  public onAPIReady = (callback: () => void) => {
    if (this.isInitialized()) {
      callback();
      return;
    }

    this.apiReadyEmitter.once('ready', callback);
  };

  /*
   * API to call
   */

  /**
   * Ask video to jump on the given frame time.
   *
   * Events to listen: {@link onSeekStarted} and {@link onSeeked}
   *
   * External action event <span style="color:green"><span style="color:green">'voomly:video:seek'</span></span>
   *
   * @group Actions
   */
  public seek = (payload: { time: number } | number) => {
    if (typeof payload === 'number') {
      console.warn(
        '[DEPRECATED] `seek` method will take object {time: number} instead of number as argument, please use new format.'
      );
      payload = { time: payload };
    }

    this.toEmitter.seek.emit(payload);
  };

  /**
   * Ask video to play.
   *
   * Must be executed only with manual action (for ex. button click). To be able to start the
   * video right after it's ready, use "autoplay" function in player editor.
   *
   * Events to listen: {@link onPlay}
   *
   * External action event <span style="color:green">'voomly:video:play'</span>
   *
   * @group Actions
   */
  public play = (payload: { time: number } | void | number) => {
    if (typeof payload === 'number') {
      console.warn(
        '[DEPRECATED] `play` method will take object {time: number} instead of number as argument, please use new format.'
      );
      payload = { time: payload };
    }

    this.toEmitter.play.emit(payload);
  };

  /**
   * Ask video to pause.
   *
   * Events to listen: {@link onPause}
   *
   * External action event <span style="color:green">'voomly:video:pause'</span>
   *
   * @group Actions
   */
  public pause = this.toEmitter.pause.emit;

  /**
   * Ask video to change volume.
   *
   * Events to listen: {@link onVolumeChange}
   *
   * External action event <span style="color:green">'voomly:video:changeVolume'</span>
   *
   * @group Actions
   */
  public changeVolume = this.toEmitter.changeVolume.emit;

  /**
   * Ask video to mute.
   *
   * Events to listen: {@link onVolumeChange}
   *
   * External action event <span style="color:green">'voomly:video:mute'</span>
   *
   * @group Actions
   */
  public mute = this.toEmitter.mute.emit;

  /**
   * Ask video to unmute.
   *
   * Events to listen: {@link onVolumeChange}
   *
   * External action event <span style="color:green">'voomly:video:unmute'</span>
   *
   * @group Actions
   */
  public unmute = this.toEmitter.unmute.emit;

  /**
   * Ask video to change video quality
   *
   * Events to listen: {@link onQualityChange}
   *
   * External action event <span style="color:green">'voomly:video:changeQuality'</span>
   *
   * @group Actions
   */
  public changeQuality = this.toEmitter.changeQuality.emit;

  /**
   * Ask video to change video playback rate
   *
   * Events to listen: {@link onSpeedChange}
   *
   * External action event <span style="color:green">'voomly:video:changeSpeed'</span>
   *
   * @group Actions
   */
  public changeSpeed = this.toEmitter.changeSpeed.emit;

  /**
   * Ask video to send back quality options.
   *
   * Events to listen: {@link onGetQualityOptions}
   *
   * External action event <span style="color:green">'voomly:video:qualityOptions'</span>
   *
   * @group Actions
   */
  public getQualityOptions = this.toEmitter.getQualityOptions.emit;

  /**
   * Ask video to send back playback rate options.
   *
   * Events to listen: {@link onGetSpeedOptions}
   *
   * External action event <span style="color:green">'voomly:video:speedOptions'</span>
   *
   * @group Actions
   */
  public getSpeedOptions = this.toEmitter.getSpeedOptions.emit;

  // Turnstile
  /**
   * Ask to enable custom turnstile integration
   * (don't send events to any consumer defined in player editor).
   *
   * Events to listen: {@link onTimelineTurnstileSkipped} and {@link onTimelineTurnstileCompleted}
   *
   * External action event <span style="color:green">'voomly:timeline:turnstile:enable'</span>
   *
   * @group Actions
   */
  public enableCustomTurnstileIntegration =
    this.toEmitter.enableCustomTurnstileIntegration.emit;

  /*
   * Events to subscribe
   */

  // Video state

  /**
   * External subscription event <span style="color:green">'voomly:video:playStarted'</span>
   *
   * @group Subscriptions
   */
  public onPlay = this.fromEmitter.videoPlayStarted.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:paused'</span>
   *
   * @group Subscriptions
   */
  public onPause = this.fromEmitter.videoPaused.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:seekStarted'</span>
   *
   * @group Subscriptions
   */
  public onSeekStarted = this.fromEmitter.videoSeekStarted.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:seeked'</span>
   *
   * @group Subscriptions
   */
  public onSeeked = this.fromEmitter.videoSeeked.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:timeUpdate'</span>
   *
   * @group Subscriptions
   */
  public onTimeUpdate = this.fromEmitter.timeUpdate.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:qualityChanged'</span>
   *
   * @group Subscriptions
   */
  public onQualityChange = this.fromEmitter.qualityChanged.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:volumeChanged'</span>
   *
   * @group Subscriptions
   */
  public onVolumeChange = this.fromEmitter.volumeChanged.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:speedChanged'</span>
   *
   * @group Subscriptions
   */
  public onSpeedChange = this.fromEmitter.speedChanged.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:ready'</span>
   *
   * @group Subscriptions
   */
  public onReady = this.fromEmitter.videoIsReady.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:ended'</span>
   *
   * @group Subscriptions
   */
  public onEnd = this.fromEmitter.videoEnded.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:qualityOptions'</span>
   *
   * @group Subscriptions
   */
  public onGetQualityOptions = this.fromEmitter.qualityOptions.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:speedOptions'</span>
   *
   * @group Subscriptions
   */
  public onGetSpeedOptions = this.fromEmitter.speedOptions.on;

  /**
   * External subscription event <span style="color:green">'voomly:video:updateDimensions'</span>
   *
   * @group Subscriptions
   */
  public onUpdateDimensions = this.fromEmitter.updateDimensions.on;

  // Unlock

  /**
   * External subscription event <span style="color:green">'voomly:timeline:unlock'</span>
   *
   * @group Subscriptions
   */
  public onTimelineUnlock = this.fromEmitter.timelineUnlock.on;

  // Turnstile

  /**
   * External subscription event <span style="color:green">'voomly:timeline:turnstile:skipped'</span>
   *
   * @group Subscriptions
   */
  public onTimelineTurnstileSkipped =
    this.fromEmitter.timelineTurnstileSkipped.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:turnstile:completed'</span>
   *
   * @group Subscriptions
   */
  public onTimelineTurnstileCompleted =
    this.fromEmitter.timelineTurnstileCompleted.on;

  // Button

  /**
   * External subscription event <span style="color:green">'voomly:timeline:button:click'</span>
   *
   * @group Subscriptions
   */
  public onTimelineButtonClick = this.fromEmitter.timelineButtonClick.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:button:enter'</span>
   *
   * @group Subscriptions
   */
  public onTimelineButtonEnter = this.fromEmitter.timelineButtonEnter.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:button:preExit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineButtonPreExit = this.fromEmitter.timelineButtonPreExit.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:button:exit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineButtonExit = this.fromEmitter.timelineButtonExit.on;

  // Image

  /**
   * External subscription event <span style="color:green">'voomly:timeline:image:click'</span>
   *
   * @group Subscriptions
   */
  public onTimelineImageClick = this.fromEmitter.timelineImageClick.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:image:enter'</span>
   *
   * @group Subscriptions
   */
  public onTimelineImageEnter = this.fromEmitter.timelineImageEnter.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:image:preExit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineImagePreExit = this.fromEmitter.timelineImagePreExit.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:image:exit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineImageExit = this.fromEmitter.timelineImageExit.on;

  // Grid

  /**
   * External subscription event <span style="color:green">'voomly:timeline:grid:click'</span>
   *
   * @group Subscriptions
   */
  public onTimelineGridClick = this.fromEmitter.timelineGridClick.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:grid:enter'</span>
   *
   * @group Subscriptions
   */
  public onTimelineGridEnter = this.fromEmitter.timelineGridEnter.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:grid:preExit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineGridPreExit = this.fromEmitter.timelineGridPreExit.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:grid:exit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineGridExit = this.fromEmitter.timelineGridExit.on;

  // Survey

  /**
   * External subscription event <span style="color:green">'voomly:timeline:survey:click'</span>
   *
   * @group Subscriptions
   */
  public onTimelineSurveyClick = this.fromEmitter.timelineSurveyClick.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:survey:enter'</span>
   *
   * @group Subscriptions
   */
  public onTimelineSurveyEnter = this.fromEmitter.timelineSurveyEnter.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:survey:preExit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineSurveyPreExit = this.fromEmitter.timelineSurveyPreExit.on;

  /**
   * External subscription event <span style="color:green">'voomly:timeline:survey:exit'</span>
   *
   * @group Subscriptions
   */
  public onTimelineSurveyExit = this.fromEmitter.timelineSurveyExit.on;

  // Annotation

  /**
   * Subscribe to annotation click
   *
   * External subscription event <span style="color:green">'voomly:timeline:annotation:click'</span>
   *
   * @group Subscriptions
   */
  public onTimelineAnnotationClick =
    this.fromEmitter.timelineAnnotationClick.on;

  // Image annotation

  /**
   * Subscribe to image annotation click
   *
   * External subscription event <span style="color:green">'voomly:timeline:imageAnnotation:click'</span>
   *
   * @group Subscriptions
   */
  public onTimelineImageAnnotationClick =
    this.fromEmitter.timelineImageAnnotationClick.on;

  /**
   * Adapter for old API subscriptions
   *
   * @deprecated Will be removed in the next versions
   * to have explicit set of API methods:
   *
   * - unlock - {@link onTimelineUnlock}<br/>
   * - timeUpdate - {@link onTimeUpdate}
   *
   * @group Deprecated
   */
  public on = (
    name: 'unlock' | 'timeUpdate',
    callback: (
      ev: FromPlayerEventTypes['timeline:unlock' | 'video:timeUpdate']
    ) => void
  ) => {
    switch (name) {
      case 'unlock':
        console.warn(
          '[DEPRECATED] `on` method will be removed in the next versions of API, please use onTimelineUnlock instead'
        );
        this.onTimelineUnlock(callback);
        break;
      case 'timeUpdate':
        console.warn(
          '[DEPRECATED] `on` method will be removed in the next versions of API, please use onTimeUpdate instead'
        );
        this.onTimeUpdate(callback);
        break;
    }
  };

  /**
   * Adapter for old API actions
   *
   * @deprecated Will be removed in the next versions
   * to have explicit set of API methods
   *
   * @internal
   * @group Deprecated
   */
  public emit = (
    name: 'unlock' | 'timeUpdate',
    ev: FromPlayerEventTypes['timeline:unlock' | 'video:timeUpdate']
  ) => {
    switch (name) {
      case 'unlock':
        console.warn(
          '[DEPRECATED] `emit` method will be removed in the next versions of API, and become internal method.'
        );
        this.fromEmitter.timelineUnlock.emit(
          ev as FromPlayerEventTypes['timeline:unlock']
        );
        break;
      case 'timeUpdate':
        console.warn(
          '[DEPRECATED] `emit` method will be removed in the next versions of API, and become internal method.'
        );
        this.fromEmitter.timeUpdate.emit(
          ev as FromPlayerEventTypes['video:timeUpdate']
        );
        break;
    }
  };
}

export type PublicPlayerExternalAPI = Omit<
  PlayerExternalAPI,
  'register' | 'fromEmitter' | 'emit'
>;

export const toPublicApi = (
  instance: PlayerExternalAPI
): PublicPlayerExternalAPI => {
  return omit(instance, ['register', 'fromEmitter']);
};
