import React from 'react';
import HlsJs, {
  HlsConfig,
  ErrorData,
  ErrorTypes,
  LoaderCallbacks,
  LoaderConfiguration,
  PlaylistLoaderContext,
} from 'hls.js/dist/hls.light.js';
import * as Sentry from '@sentry/browser';

const HLS_MAX_MAX_BUFFER_LENGTH = 10; // Max number of seconds to load to buffer
const BOT_SCORE_THRESHOLD = 15; // 1 - definitely bot, 100 - definitely not bot

interface IProps {
  url: string;
  load: boolean;
  quality: number;
  startPosition?: number;
  onError?: (errorData: string | ErrorData) => void;
  onReady?: () => void;
  onBotDetection?: (isBotDetected: boolean) => void;
  skipBotDetection?: boolean;
  isBotDetected?: boolean;
  autoplay?: boolean;
  children: ({
    registerVideoRef,
  }: {
    registerVideoRef: (video: HTMLVideoElement | null | undefined) => void;
  }) => React.ReactNode;
}

type IState = {
  isFirstManifestLoaded: boolean;
};

export class Hls extends React.PureComponent<IProps, IState> {
  static defaultProps = {
    load: false,
    quality: -1,
  };

  private hls: HlsJs | undefined = undefined;
  private video?: HTMLVideoElement | null;
  // contains the last estimated bandwidth by hls.js
  private lastEstimatedBandwidth?: number | null;

  manifestParsed = false;

  state = {
    isFirstManifestLoaded: false,
  };

  componentDidMount() {
    // @ts-ignore
    window.hlsComponent = this;

    this.updateHlsUrl();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.url !== this.props.url) {
      // We don't check if bot detected when we move to next video
      this.props.onBotDetection?.(false);

      /* try to extract bandwidthEstimate from the previous hls.js instance
       * we use it to start the next video using the last estimated bandwidth
       * to start the video with suitable quality
       */
      const prevBandwidthEstimate = this.hls?.bandwidthEstimate;

      if (
        prevBandwidthEstimate &&
        prevBandwidthEstimate !== this.lastEstimatedBandwidth
      ) {
        console.debug('prevBandwidthEstimate:', prevBandwidthEstimate);

        this.lastEstimatedBandwidth = Math.floor(prevBandwidthEstimate);
      } else {
        this.lastEstimatedBandwidth = null;
      }

      this.hls?.destroy();
      this.manifestParsed = false;
      this.updateHlsUrl();
    }

    if (this.hls) {
      if (prevProps.quality !== this.props.quality) {
        this.hls.currentLevel =
          this.props.quality === -1
            ? -1
            : this.hls.levels.length - this.props.quality - 1;
      }

      if (
        this.props.load &&
        prevProps.load !== this.props.load &&
        this.manifestParsed
      ) {
        this.hls.startLoad();
      }

      if (
        !this.props.autoplay &&
        this.state.isFirstManifestLoaded &&
        !prevState.isFirstManifestLoaded &&
        !this.props.isBotDetected
      ) {
        this.hls.startLoad();
      }
    }
  }

  componentWillUnmount() {
    this.hls?.destroy();
  }

  handleManifestLoaded = (isBotDetected: boolean) => {
    const { onBotDetection, skipBotDetection } = this.props;
    const { isFirstManifestLoaded } = this.state;

    try {
      // Update with real value if bot is detected
      if (!skipBotDetection && !isFirstManifestLoaded) {
        if (this.hls) {
          this.hls.config.autoStartLoad = !isBotDetected;
        }

        onBotDetection?.(isBotDetected);
        return;
      }

      // Every next update will set "is bot detected" to false
      // We don't need to have issues after autoplay passed
      if (this.hls) {
        this.hls.config.autoStartLoad = true;
      }
      onBotDetection?.(false);
    } finally {
      this.setState({
        isFirstManifestLoaded: true,
      });
    }
  };

  private updateHlsUrl() {
    const { url, load, onError, onReady, skipBotDetection } = this.props;
    const videoEl = this.video;

    if (!videoEl) {
      return;
    }

    if (!HlsJs.isSupported() || !url) {
      if (onError) onError('HLS is not supported by the browser');
      return;
    }

    const onManifestLoaded = this.handleManifestLoaded;

    const params: Partial<HlsConfig> = {
      autoStartLoad: load,
      capLevelToPlayerSize: true,
      debug: false,
      maxMaxBufferLength: HLS_MAX_MAX_BUFFER_LENGTH,
      startPosition: this.props.startPosition,
      startLevel: -1, // start from the lowest quality
      pLoader: class CustomLoader extends HlsJs.DefaultConfig.loader {
        load(
          context: PlaylistLoaderContext,
          config: LoaderConfiguration,
          callbacks: LoaderCallbacks<PlaylistLoaderContext>
        ) {
          const { type } = context;

          if (type === 'manifest' && !skipBotDetection) {
            const onSuccess = callbacks.onSuccess.bind(this);
            callbacks.onSuccess = (response, stats, context, xhr: any) => {
              const score = xhr.getResponseHeader('x-bot-score');
              console.debug('BOT Score:', score);

              let isBotDetected = false;
              if (score < BOT_SCORE_THRESHOLD) {
                console.debug('BOT Detected');
                isBotDetected = true;
              }

              onManifestLoaded(isBotDetected);
              onSuccess(response, stats, context, xhr);
            };
          }

          super.load(context, config, callbacks);
        }
      } as HlsConfig['pLoader'],
    };

    if (this.lastEstimatedBandwidth) {
      console.debug(
        'Use defaultBandwidthEstimate',
        this.lastEstimatedBandwidth
      );

      // set default precalculated bandwidth to say hls.js which quality it has to choose
      params.abrEwmaDefaultEstimate = this.lastEstimatedBandwidth;
      // if we provide abrEwmaDefaultEstimate we have to disable testBandwidth otherwise hls.js will ignore abrEwmaDefaultEstimate
      params.testBandwidth = false;
    }

    console.debug('HLS init params: ', params);

    this.hls = new HlsJs(params);

    const safeHls = this.hls;
    safeHls.attachMedia(videoEl);
    safeHls.on(HlsJs.Events.MEDIA_ATTACHED, () => {
      // <video> and hls.js are now bound together
      safeHls.loadSource(url);

      safeHls.on(HlsJs.Events.MANIFEST_PARSED, () => {
        this.manifestParsed = true;
        onReady?.();
      });
    });

    safeHls.on(HlsJs.Events.FRAG_LOADED, () => {
      this.props.onBotDetection?.(false);
    });

    safeHls.on(HlsJs.Events.ERROR, (event, data) => {
      const errorType = data.type;
      const errorDetails = data.details;
      const errorFatal = data.fatal;

      if (errorFatal) {
        switch (errorType) {
          case ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            // fatal network error encountered, try to recover
            safeHls.startLoad();
            break;
          case ErrorTypes.MEDIA_ERROR:
            // fatal media error encountered, try to recover
            safeHls.recoverMediaError();
            break;
          default:
            // cannot recover
            safeHls.destroy();
            if (onError) onError(data);
            break;
        }
      } else {
        // non fatal error, safe to ignore
        console.error('player error, but continue', data);

        if (data.details === 'internalException') {
          Sentry.captureException(data);
        }
      }
      console.log({ errorType, errorDetails, errorFatal });
    });
  }

  registerVideoRef = (node: HTMLVideoElement | null | undefined) => {
    this.video = node;
  };

  render() {
    const { children } = this.props;
    return children({
      registerVideoRef: this.registerVideoRef,
    });
  }
}
